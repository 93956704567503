<template>
  <div class="relative p-0 md:p-24 w-full sm:w-[560px]">
    <div class="bg-white p-[20px] sm:p-[40px] w-full sm:rounded-lg sm:shadow-lg z-10">
      <h2 v-if="props.title" class="text-5xl font-semibold text-gray-800">{{ props.title }}</h2>
      <p v-if="props.subtitle" class="text-lg my-4 text-neutral-low-ultra" v-html="props.subtitle"></p>

      <div class="flex flex-col gap-4">
        <div class="relative">
          <SearchAddress
            :saved-address="searchForm.location"
            label=""
            icon="uil uil-search"
            @address-choosed="handleAddressSelected"
            @value-changed="handleAddressSelected"
          />
        </div>

        <div class="flex gap-4">
          <CampaignDurationPicker
            v-model="searchForm.duration"
            label=""
            placeholder="duração"
            class="form-field w-1/2"
          />

          <CampaignDatePicker
            v-model="searchForm.start"
            label=""
            placeholder="início"
            class="form-field w-1/2 form-field-wrapper ui-state-filled"
          />
        </div>

        <Button label="buscar" class="w-full mt-2" @click="searchHandler" />
      </div>
    </div>
  </div>
</template>

<script setup>
import CampaignDatePicker from "@components/pages/SearchLocation/components/CampaignDatePicker.vue";
import CampaignDurationPicker from "@components/pages/SearchLocation/components/CampaignDurationPicker.vue";
import { useSearchProductsStore } from "@store/SearchProductsStore";
import { ref } from "vue";

import SearchAddress from "@/components/pages/SearchLocation/components/SearchAddress.vue";

const ResultsStore = useSearchProductsStore();
const searchForm = ref({
  location: undefined,
  duration: undefined,
  start: undefined,
});

const emit = defineEmits(["search"]);
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "Explore onde anunciar",
  },
  subtitle: {
    type: String,
    required: false,
    default: undefined,
  },
});
const searchHandler = () => {
  emit("search", { ...searchForm.value });
};

const handleAddressSelected = (address) => {
  searchForm.value.location = {
    enderecoId: address.id,
    endereco: address.name,
  };
};
</script>
