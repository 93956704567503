<template>
  <div
    class="search-hero relative w-full sm:h-screen flex flex-col items-start justify-end sm:justify-center overflow-hidden"
  >
    <template v-if="isDesktop">
      <video class="absolute top-0 left-0 w-full h-full object-cover" autoplay loop muted>
        <source
          src="https://storage.cloud.google.com/elt-smb-prod/assets/home/content/home-busca.mp4?authuser=0"
          type="video/mp4"
        />
      </video>
      <div class="absolute top-0 left-0 inset-0 w-full h-full bg-black bg-opacity-50"></div>
      <img
        class="absolute bottom-0 left-0 object-cover sm:left-[-200px] mr-0 md:mr-12"
        width="100%"
        :src="arrowBgImage"
      />

      <SearchFormBox :title="searchBoxConfig.title" :subtitle="searchBoxConfig.subtitle" @search="searchHandler" />
    </template>

    <template v-if="isMobile">
      <div class="px-[20px]">
        <img src="@/assets/bg-search-mobile.png" class="w-full rounded-lg" />
      </div>
      <SearchFormBox
        :title="searchBoxConfig.title"
        :subtitle="searchBoxConfig.subtitleMobile"
        @search="searchHandler"
      />
    </template>
  </div>
</template>

<script setup>
import SearchFormBox from "@components/pages/Home/SearchFormBox.vue";
import useIsMobile from "@hooks/useIsMobile";
import { useSearchProductsStore } from "@store/SearchProductsStore";

import arrowBgImage from "@/assets/bg-mark-home.svg";
import { rotaResultados } from "@/routes";

const { isMobile, isDesktop } = useIsMobile();
const ResultsStore = useSearchProductsStore();

const searchBoxConfig = {
  title: "Explore onde anunciar",
  subtitle: "Faça sua empresa brilhar no bairro com as telas da Eletromidia",
  subtitleMobile: "Faça sua empresa brilhar no bairro<br />com as telas da Eletromidia",
};

const searchHandler = ({ location, duration, start }) => {
  ResultsStore.setSearch({
    duration: duration,
    start: start,
    address: {
      id: location.enderecoId,
      name: location.endereco,
    },
  });
  window.location.href = rotaResultados.url;
};
</script>

<style scoped></style>
