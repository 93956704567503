<template>
  <section v-if="!isMobile" class="flex w-full">
    <div class="relative flex h-[390px] w-1/3 flex-col justify-center bg-[#FF4F00] px-[80px] 2xl:px-[124px]">
      <p class="-mt-12 mb-[20px] text-5xl font-bold !text-[#282E38]">Esteja em todos os lugares</p>
      <p class="mb-[20px] text-lg !text-[#282E38]">
        Fale com o seu público-alvo fora de casa. Faça parte da jornada do seu cliente por todo o bairro.
      </p>
    </div>
    <div class="relative flex h-[390px] w-1/3 flex-col justify-center bg-status-pending-idle px-[80px] 2xl:px-[124px]">
      <p class="-mt-12 mb-[20px] text-5xl font-bold !text-[#282E38]">A sua marca merece brilhar</p>
      <p class="mb-[20px] text-lg !text-[#282E38]">
        Torne a sua marca visível por todos em seu bairro. Quando você anuncia com a Eletromidia, todo mundo vê!
      </p>
      <Button
        class="lg:mb-18 absolute bottom-24 left-1/2 -translate-x-1/2 w-fit !bg-white !px-[17.5px] !py-[10.5px] !text-neutral-gray7 max-lg:mx-auto max-md:!w-full 2xl:mb-4"
        @click="handleRedirectToResults()"
      >
        divulgue sua empresa
      </Button>
    </div>
    <div class="relative flex h-[390px] w-1/3 flex-col justify-center bg-[#FF74FF] px-[80px] 2xl:px-[124px]">
      <p class="-mt-12 mb-[20px] text-5xl font-bold !text-[#282E38]">Atraia novos clientes</p>
      <p class="mb-[20px] text-lg !text-[#282E38]">
        Com mais visibilidade, você se torna mais conhecido em sua região e atrai mais clientes para o seu negócio.
      </p>
    </div>
  </section>
  <section v-else class="w-full">
    <swiper class="!border-none !outline-none" :slides-per-view="1.4" :space-between="0" pagination loop grab-cursor>
      <swiper-slide>
        <div class="flex h-[390px] w-full flex-col bg-[#FF4F00] px-[24px]">
          <p class="mb-[20px] mt-24 text-5xl font-bold !text-[#282E38]">Esteja em todos <br />os lugares</p>
          <p class="mb-[20px] text-lg !text-[#282E38]">
            Fale com o seu público-alvo fora de casa. Faça parte da jornada do seu cliente por todo o bairro.
          </p>
          <Button
            class="absolute bottom-0 mb-24 !w-fit !bg-white !px-[17.5px] !text-neutral-gray7"
            @click="handleRedirectToResults()"
          >
            entre na jornada do cliente
          </Button>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="flex h-[390px] w-full flex-col bg-status-pending-idle px-[24px]">
          <p class="mb-[20px] mt-24 text-5xl font-bold !text-[#282E38]">A sua marca <br />merece brilhar</p>
          <p class="mb-[20px] text-lg !text-[#282E38]">
            Torne a sua marca visível por todos em seu bairro. Quando você anuncia com a Eletromidia, todo mundo vê!
          </p>
          <Button
            class="absolute bottom-0 mb-24 !w-fit !bg-white !px-[17.5px] !text-neutral-gray7"
            @click="handleRedirectToResults()"
          >
            divulgue sua marca
          </Button>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="flex h-[390px] w-full flex-col bg-[#FF74FF] px-[24px]">
          <p class="mb-[20px] mt-24 text-5xl font-bold !text-[#282E38]">Atraia novos <br />clientes</p>
          <p class="mb-[20px] text-lg !text-[#282E38]">
            Com mais visibilidade, você se torna mais conhecido em sua região e atrai mais clientes para o seu negócio.
          </p>
          <Button
            class="absolute bottom-0 mb-24 !w-fit !bg-white !px-[17.5px] !text-neutral-gray7"
            @click="handleRedirectToResults()"
          >
            amplie sua base
          </Button>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script setup>
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/vue";

import useIsMobile from "@/hooks/useIsMobile";
import { rotaResultados } from "@/routes";

const { isMobile } = useIsMobile();

const handleRedirectToResults = () => {
  location.href = rotaResultados.url;
};
</script>

<style lang="scss" scoped></style>
